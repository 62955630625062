import { Pipe, PipeTransform } from "@angular/core";
/**
 * Pipe used to sort Data
 */
@Pipe({
  name: "sort"
})
export class SortPipe  implements PipeTransform {
  /**
   * Sort Array by field
   * @param array 
   * @param field 
   * @returns 
   */
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}