import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe used to anonymize a String after 4 chars
 */
@Pipe({
  name: 'anonymize'
})
export class AnonymizePipe implements PipeTransform {
/**
 * Transform a String to anonymize a String after 4 chars
 * @param value 
 * @param args 
 * @returns 
 */
  transform(value: string, ...args: unknown[]): string {
    return value.substring(0,5) + Array(value.length-4).join("*") +" - "+ (args[0]['id']);
  }

}
