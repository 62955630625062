import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the Service
 */
@Pipe({
  name: 'translateFilterServices'
})
export class TranslateFilterServicesPipe implements PipeTransform {

  /**
   * transform the state based on the Type
   * @param value 
   * @param type 
   * @returns 
   */
  transform(value: string[], type: string): string[] {
    if (type == 'filterByRoles') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'ROLE_ANON':
            if (returnArray.length == 0) {
              returnArray.push("Anonymous");
            }
            break;
          case 'ROLE_PRE_CUSTOMER':
            if (returnArray.length == 0) {
              returnArray.push("Vorkunde");
            }
            break;
          case 'ROLE_CUSTOMER':
            if (returnArray.length == 0) {
              returnArray.push("Kunde/Mitarbeiter");
            }
            break;
          case 'ROLE_PARTNER':
            if (returnArray.length == 0) {
              returnArray.push("Aktionspartner");
            }
            break;
          case 'ROLE_CASEWORKER':
            if (returnArray.length == 0) {
              returnArray.push("Sachbearbeiter");
            }
            break;
          case 'ROLE_ADMIN':
            if (returnArray.length == 0) {
              returnArray.push("Administrator");
            }
            break;
        }
      });
      return returnArray
    } else if (type == 'filterByStates') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'DISABLED':
            if (returnArray.length == 0) {
              returnArray.push("deaktiviert");
            }
            break;
          case 'ACTIVE':
            if (returnArray.length == 0) {
              returnArray.push("aktiviert");
            }
            break;
        }
      })
      return returnArray
    } else if (type == 'filterByModules') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'event':
            if (returnArray.length == 0) {
              returnArray.push("Aktionswelt");
            }
            break;
          case 'news':
            if (returnArray.length == 0) {
              returnArray.push("Infothek");
            }
            break;
          case 'card':
            if (returnArray.length == 0) {
              returnArray.push("Karte");
            }
            break;
        }
      })
      return returnArray
    }
  }

}
